import { init } from '@elastic/apm-rum';

import { Logger } from './lib/logger';

if (window.env?.stage === 'prod') {
  init({
    active: true,
    serverUrl: '/api/apm',
    serviceName: 'module-video-call',
    serviceVersion: window.env?.version ?? 'local',
    environment: window.env?.stage ?? 'dev',
  });
} else {
  Logger.info('APM', 'APM only works for prod!');
}

import('./standalone');
