/* eslint-disable @typescript-eslint/no-unsafe-argument */
import LogT from 'logt';

// TODO.awu: check if this needs to be changed before the final release
// const _Logger = process.env.NODE_ENV === 'production' ? new LogT('error') : new LogT('silly');
const _Logger = new LogT('silly');

type LogTags =
  | 'CONFIGURATION'
  | 'STORE'
  | 'AUDIO'
  | 'VIDEO'
  | 'DATA'
  | 'SCREENSHARE'
  | 'MONKEY'
  | 'CHAT'
  | 'SETTINGS'
  | 'ROOM'
  | 'PERMISSIONS'
  | 'SSE'
  | 'SESSIONS'
  | 'APM'
  | 'MAIL';

function info(
  tag: LogTags,
  message: Parameters<InstanceType<typeof LogT>['info']>[1],
  ...parts: Parameters<InstanceType<typeof LogT>['info']>[2]
): void {
  _Logger.info(tag, message, ...parts);
}

function warn(
  tag: LogTags,
  message: Parameters<InstanceType<typeof LogT>['warn']>[1],
  ...parts: Parameters<InstanceType<typeof LogT>['warn']>[2]
): void {
  _Logger.warn(tag, message, ...parts);
}

function error(
  tag: LogTags,
  message: Parameters<InstanceType<typeof LogT>['error']>[1],
  ...parts: Parameters<InstanceType<typeof LogT>['error']>[2]
): void {
  _Logger.error(tag, message, ...parts);
}

export const Logger = {
  error,
  info,
  warn,
};
